import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { EntityNewsList } from "./entity";
const definition: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "News list",
  type: "bridge-news-list",
  events: {
    createBlock: (): DMEData.Block<EntityNewsList> => {
      return {
        id: nanoid(),
        type: "bridge-news-list",
        data: {
          list: [],
          count: 0,
          settings: {
            limit: 10,
          },
        },
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Items per page",
      property: "settings.limit",
      settingComponent: "number",
      parameters: { min: 1, max: 20 },
    },
    {
      name: "Hide pagination",
      property: "settings.hidePagination",
      settingComponent: "checkbox",
    },
  ],
};

export default definition;
