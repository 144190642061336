import { DMEditor, DMEditorRefType } from "dmeditor";
import { useEffect, useRef, useState } from "react";
import { FetchWithAuth } from "digimaker-ui/util";
import { useNavigate } from "react-router";
import { dmeditorFields } from "./fieldConfig";
import { pageSettings } from "./pageSetting";
import { useSearchParams } from "react-router-dom";
import { getArticleSummaryFromList } from "../../../components/util";

export const Edit = (props: { id: number }) => {
  const editorRef = useRef<DMEditorRefType>(null);
  const nav = useNavigate();
  const [searchParams] = useSearchParams();
  let contentType = "";

  const save = (data) => {
    const postData = {
      title: data.page.title,
      coverimage: data.page.cover_image || "",
    };
    postData[dmeditorFields[contentType]] = data.data;
    if (contentType === "article") {
      const summaryHtml = getArticleSummaryFromList(data.data);
      postData["summary"] = summaryHtml;
    }

    FetchWithAuth(`content/update/${props.id}`, {
      method: "POST",
      body: JSON.stringify(postData),
    }).then((data) => {
      if (data.error === false) {
        window.alert("Saved. Click to return");
        if (searchParams.has("from")) {
          nav(("/website" + searchParams.get("from")) as string);
        } else {
          nav(`/website/main/${props.id}`);
        }
      }
    });
  };

  const cancel = () => {
    if (searchParams.has("from")) {
      nav(("/website" + searchParams.get("from")) as string);
    } else {
      nav(`/website/main/${props.id}`);
    }
  };

  useEffect(() => {
    const current = editorRef.current;
    if (current) {
      current.setPageSettings(pageSettings as any);
    }

    FetchWithAuth(`content/get/${props.id}`)
      .then((data) => {
        if (data.error === false) {
          contentType = data.data.metadata.contenttype;
          const list = data.data[dmeditorFields[contentType]];
          const page = {
            title: data.data.title,
            cover_image: data.data.coverimage,
          };
          current?.setData(list || []);
          current?.setPageData(page);
        }
      })
      .catch((d) => {
        window.alert(d.message);
        nav(`/`);
      });
  }, [props.id]);

  return <DMEditor ref={editorRef} onSave={save} onCancel={cancel} />;
};
