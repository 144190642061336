import { DME } from "dmeditor";
import moment from "moment";
import { useEffect, useState } from "react";
import { Tabs, Tab, Table } from "react-bootstrap";
import { EntityResult } from "./entity";
import { Link } from "../Shared";
import { siteEnv } from "../siteEnv";
import { wrapBasePath } from "../util";

const Result = (props: DME.WidgetRenderProps<EntityResult>) => {
  const {
    blockNode: { data },
  } = props;

  const [value, setValue] = useState(data.value);
  const [activeTab, setActiveTab] = useState(value ? value.years[0] : "");

  moment.locale("nb");

  const dateWidth = 150;

  const fetchData = (ids: number[]) => {
    if (ids.length == 0) {
      return;
    }
    const id = ids[0];
    fetch(`https://www.bridge.no/result/list/${id}?json=true`)
      .then((res) => res.json())
      .then((data) => {
        setValue(data);
      });
  };

  useEffect(() => {
    if (!props.blockNode.serverData) {
      const ids = getFolderIDs(data.folder || "");
      fetchData(ids);
    }
  }, []);

  useEffect(() => {
    if (props.mode === "edit") {
      const ids = getFolderIDs(data.folder || "");
      fetchData(ids);
    }
  }, [props.blockNode.data.folder]);

  useEffect(() => {
    if (value) {
      const activeTab =
        new URLSearchParams(window.location.search).get("tab") ||
        value.years[0];
      setActiveTab(activeTab);
    }
  }, [value]);

  if (!value) {
    return <div>Loading...</div>;
  }

  if (value && (Object.keys(value).length === 0 || value.years.length == 0)) {
    return <div className="alert alert-warning">Ikke data funnet.</div>;
  }

  const fromUrl = "../resultater%3Ftab=" + activeTab;

  return (
    <div className="bg-white container">
      <Tabs
        activeKey={activeTab}
        className="mb-3"
        onSelect={(k) => setActiveTab(k)}
      >
        {value.years.map(
          (year, index) =>
            value.contest_list[year] && (
              <Tab eventKey={year} title={year}>
                <div>
                  {value.summary_list[year] && (
                    <>
                      <h3>Sesongresultater</h3>
                      <div>
                        {Object.keys(value.summary_list[year]).map((name) => (
                          <div>
                            {(() => {
                              let contestName =
                                value.summary_list[year][name].ContestName;
                              let link = wrapBasePath(
                                "/result/" +
                                  name +
                                  "?summary=true&from=" +
                                  fromUrl
                              );
                              let newTarget = false;
                              if (name.endsWith("x")) {
                                contestName = contestName + " *";
                                link =
                                  "https://www.bridge.no/var/ruter/html/" +
                                  value.club_no +
                                  "/" +
                                  name +
                                  ".htm";
                                newTarget = true;
                              }

                              return (
                                <Link newTarget={newTarget} to={link}>
                                  {contestName}
                                  {newTarget && (
                                    <>
                                      &nbsp;
                                      <i className="bi bi-box-arrow-up-right"></i>
                                    </>
                                  )}
                                </Link>
                              );
                            })()}
                          </div>
                        ))}
                      </div>
                      <br />
                    </>
                  )}

                  {(() => {
                    const yearData = value.contest_list[year];
                    const orderedYearData: Array<any> = [];
                    for (const key of Object.keys(yearData)) {
                      orderedYearData.push({ ...yearData[key], file: key });
                    }
                    orderedYearData.sort((a: any, b: any) => {
                      const aUnix = moment(a.ContestDate as string).unix();
                      const bUnix = moment(b.ContestDate as string).unix();
                      return bUnix - aUnix;
                    });

                    let group = {} as any;
                    let month = "";
                    let monthMap = {
                      "01": "jan",
                      "02": "feb",
                      "03": "mar",
                      "04": "apr",
                      "05": "mai",
                      "06": "jun",
                      "07": "jul",
                      "08": "august",
                      "09": "sep",
                      "10": "okt",
                      "11": "nov",
                      "12": "des",
                    };
                    for (const record of orderedYearData) {
                      let dateMoment = moment(record.ContestDate as string);
                      let currentMonth = dateMoment.format("MM");
                      if (currentMonth !== month) {
                        group[currentMonth] = [];
                        month = currentMonth;
                      }
                      group[month].push(record);
                    }
                    let months = Object.keys(group);
                    months.sort((a, b) => {
                      return parseInt(b) - parseInt(a);
                    });
                    return months.map((month) => (
                      <>
                        <h3>
                          {monthMap[month]} {year}
                        </h3>
                        <Table striped>
                          <tbody>
                            {group[month].map((record) => (
                              <tr>
                                <td style={{ width: dateWidth }}>
                                  {moment(record.ContestDate as string).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td>
                                  {(() => {
                                    let link = "";
                                    let newTarget = false;
                                    if (
                                      ["1", "2"].includes(record["ContestType"])
                                    ) {
                                      link = wrapBasePath(
                                        "/result/" +
                                          record["file"] +
                                          "?from=" +
                                          fromUrl
                                      );
                                    } else if (
                                      record["ContestType"] === "4" &&
                                      record["URL"]
                                    ) {
                                      link = record["URL"];
                                      newTarget = true;
                                    } else {
                                      link =
                                        "https://www.bridge.no/var/ruter/html/" +
                                        value.club_no +
                                        "/" +
                                        record["file"] +
                                        ".htm";
                                      newTarget = true;
                                    }

                                    return (
                                      <Link to={link} newTarget={newTarget}>
                                        {record.ContestName}
                                        {newTarget && (
                                          <>
                                            &nbsp;
                                            <i className="bi bi-box-arrow-up-right"></i>
                                          </>
                                        )}
                                      </Link>
                                    );
                                  })()}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    ));
                  })()}
                </div>
              </Tab>
            )
        )}
      </Tabs>
    </div>
  );
};

const onServerSideLoad = async (blockData, context) => {
  const ids = getFolderIDs(blockData.data.folder || "");
  if (ids.length > 0) {
    const res = await fetch(
      `https://www.bridge.no/result/list/${ids[0]}?json=true`
    );
    const jsonData = await res.json();
    blockData.data.value = jsonData;
  } else {
    blockData.data.value = {};
  }
  blockData.serverData = true;
};

const getFolderIDs = (folderStr: string) => {
  const result: number[] = [];
  if (folderStr) {
    const arrStr = folderStr.split(",");
    for (const item of arrStr) {
      result.push(parseInt(item.trim()));
    }
  } else {
    if (siteEnv.clubID) {
      result.push(siteEnv.clubID);
    } else if (siteEnv.kretsID) {
      result.push(siteEnv.kretsID);
    }
  }
  return result;
};

export { Result, onServerSideLoad };
